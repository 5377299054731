import { Badge, Button, Flex, Group, Space, Stack, Text } from '@mantine/core'
import Loader from 'components/common/Loader'
import useNavigateBack from 'hooks/useNavigateBack'
import { useShowtime, useShowtimeReservationUrl } from 'queries/showtimes'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { ArrowRight, Ticket } from 'tabler-icons-react'
import type { Movie, Theater } from 'types/showtimes'
import {
  formatShowtimeDate,
  showtimeIsAVP,
  showtimeIsUnavailable,
  showtimePriceText,
} from 'utils/showtimes'

import { CurrentMovieProvider } from 'providers/currentMovieProvider'
import MovieModalContent from './MovieModalContent'
import SynopsisTab from './SynopsisTab'

type RouteParams = {
  showtimeId: string
}

function ShowtimePop({ movie, theater }: { movie: Movie; theater: Theater }) {
  const showtime = theater.showtimes[0]
  const { data: reservationUrl, isLoading } = useShowtimeReservationUrl(
    showtime.id,
    true
  )
  const displayTitle = movie.releaseDate
    ? `${movie.titleVf} (${movie.releaseDate.year()})`
    : movie.titleVf
  const isUnavailable = showtimeIsUnavailable(showtime.showtime)
  const isAvantPremiere = showtimeIsAVP(showtime.showtime, movie.releaseDate)
  const price = showtimePriceText(theater, showtime)
  const showtimeStr = formatShowtimeDate(showtime.showtime)
  return (
    <div className="relative overflow-hidden px-3 py-2 bg-zinc-900 text-white">
      <Helmet>
        <title>
          {displayTitle} - Séance au {theater.name} le {showtimeStr}
        </title>
        <meta
          name="description"
          content={`Séance du film ${movie.titleVf} au {theater.name} le {showtimeStr}. ${movie.synopsis} `}
        />
        <meta property="og:title" content={displayTitle} />
        <meta property="og:description" content={movie.synopsis} />
        <meta property="og:image" content={movie.posterPath} />
        <meta
          property="og:url"
          content={`${window.location.origin}/seance/${showtime.id}`}
        />
      </Helmet>
      <Stack gap={2} className="mx-auto max-w-5xl">
        <Link
          to={`/cinema/${theater.urlTitle}-${theater.id}`}
          state={{ hasPrev: true }}
        >
          <Text className="truncate pr-1 font-semibold">{theater.name}</Text>
        </Link>
        <Group gap="md">
          <Text className="font-normal capitalize">
            {showtimeStr} -{' '}
            {showtime.vo || showtime.audio === null
              ? 'VO'
              : `V${showtime.audio[0].toUpperCase()}`}
          </Text>
          <>
            <Badge className="bg-white text-zinc-900">{price}</Badge>

            {isAvantPremiere && (
              <Badge className="bg-yellow-500 text-white">AVP</Badge>
            )}
          </>
        </Group>
        <Text size="xs" className="inline font-light">
          {showtime.extraInfo && `${showtime.extraInfo}`}
          {showtime.is3d && ' - 3D'}
        </Text>
        <Space h={5} />
        <Group justify="space-between" className="flex items-stretch">
          <Button
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={reservationUrl}
            loading={isLoading}
            styles={() => ({
              label: {
                whiteSpace: 'normal',
                fontWeight: 'normal',
              },
            })}
            leftSection={<Ticket size={20} />}
            className="max-w-[42vw] whitespace-normal text-center bg-zinc-600 text-white"
            disabled={
              !reservationUrl || reservationUrl?.length === 0 || isUnavailable
            }
          >
            <Text size="xs">
              {(() => {
                if (isUnavailable) {
                  return 'Séance passée'
                }
                if (!reservationUrl) {
                  return 'Pas de réservation'
                }
                return 'Réserver'
              })()}
            </Text>
          </Button>
          <Button
            component={Link}
            to={`/film/${movie.urlTitle}-${movie.id}/seances`}
            rightSection={<ArrowRight size={20} />}
            styles={() => ({
              label: {
                whiteSpace: 'normal',
                fontWeight: 'normal',
              },
            })}
            className="max-w-[42vw] text-center bg-zinc-600 text-white"
          >
            <Text size="xs">Autres séances</Text>
          </Button>
        </Group>
      </Stack>
    </div>
  )
}

export default function ShowtimePage() {
  const { showtimeId } = useParams<RouteParams>()
  const { data: movieWithShowtimes, isError } = useShowtime(showtimeId)
  const { movie, theaters } = movieWithShowtimes || {}
  const navigateBack = useNavigateBack()

  if (!movie || !theaters) {
    return <Loader />
  }
  if (isError) {
    navigateBack()
  }

  return (
    <CurrentMovieProvider movieId={movie.id}>
      <Flex direction="column" className="h-[var(--page-height)] bg-white">
        <div className="flex-1 overflow-auto">
          <MovieModalContent />
          <div className="max-w-7xl mx-auto mt-4">
            <SynopsisTab />
          </div>
        </div>
        <div className="flex-none shadow-md">
          <ShowtimePop movie={movie} theater={theaters[0]} />
        </div>
      </Flex>
    </CurrentMovieProvider>
  )
}
