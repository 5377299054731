import Loader from 'components/common/Loader'
import useNavigateBack from 'hooks/useNavigateBack'
import { useMovieAllTheaters, useMovieShowtimes } from 'queries/showtimes'
import { type ReactNode, createContext, useEffect, useMemo } from 'react'
import useSearchStore from 'states/useSearchStore'
import type { Movie, Theater } from 'types/showtimes'
import useValidContext from './common'

type CurrentMovieContextType = {
  movieId: number
  movie: Movie
  matchingTheaters: Theater[]
  allTheaters: Theater[]
}

const currentMovieContext = createContext<CurrentMovieContextType | null>(null)

export const useCurrentMovie = () => useValidContext(currentMovieContext)

type CurrentMovieProviderProps = {
  children: ReactNode | ReactNode[]
  movieId: string | number | undefined
}

export function CurrentMovieProvider({
  children,
  movieId,
}: CurrentMovieProviderProps) {
  const navigateBack = useNavigateBack()
  const searchParams = useSearchStore((state) => state.searchParams)
  const updateMovieId = useSearchStore((state) => state.updateMovieId)
  const { data: movieWithShowtimes, isError } = useMovieShowtimes(
    searchParams,
    movieId
  )
  const { data: allTheaters, isError: allIsError } = useMovieAllTheaters(
    searchParams,
    movieId
  )

  const contextValue = useMemo(() => {
    if (!movieWithShowtimes || !allTheaters) {
      return null
    }
    const { movie, theaters: matchingTheaters } = movieWithShowtimes
    return { movie, movieId: movie.id, matchingTheaters, allTheaters }
  }, [movieWithShowtimes, allTheaters])

  useEffect(() => {
    if (isError || allIsError) {
      navigateBack()
    }
  }, [isError, navigateBack])

  useEffect(() => {
    // keep movieId for search range computation
    updateMovieId(movieId?.toString())
    return () => updateMovieId(undefined)
  }, [movieId, updateMovieId])

  if (!movieWithShowtimes || !allTheaters) {
    return <Loader />
  }

  return (
    <currentMovieContext.Provider value={contextValue}>
      {children}
    </currentMovieContext.Provider>
  )
}
