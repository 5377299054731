/* eslint-disable react/jsx-props-no-spreading */

import {
  Combobox,
  Image,
  Stack,
  Text,
  TextInput,
  useCombobox,
} from '@mantine/core'
import { useDebouncedValue, useViewportSize } from '@mantine/hooks'
import dayjs from 'dayjs'
import React, { useState, useEffect, useRef, useCallback } from 'react'

import trackEvent from 'queries/event'
import { useMoviesSearch } from 'queries/movies'
import { useTheatersSearch } from 'queries/theaters'
import { useNavigate } from 'react-router-dom'
import type { Movie, Theater } from 'types/showtimes'

interface ItemProps {
  id: string
  movie: Movie | null
  theater: Theater | null
}

function AutoCompleteItem({ id, movie, theater }: ItemProps) {
  return (
    <div key={id} className="flex p-2 hover:cursor-pointer">
      {movie?.posterPath && (
        <div className="mr-2">
          <Image
            h="auto"
            w="3.6rem"
            radius="sm"
            src={movie?.posterPath}
            alt={movie?.titleVo}
          />
        </div>
      )}
      <Stack gap={1} w="10rem">
        <Text span size="sm" ta="left">
          {movie?.titleVf || theater?.name}
          {movie?.releaseDate && (
            <Text size="xs" className="text-zinc-500 inline pl-1">
              ({dayjs(movie.releaseDate).locale('fr').format('YYYY')})
            </Text>
          )}
        </Text>
        <Text size="xs" ta="left" className="text-zinc-500" lineClamp={1}>
          {movie?.director ||
            `${theater?.zipCode} ${theater?.city}, ${theater?.country}`}
        </Text>
      </Stack>
    </div>
  )
}

type Props = {
  opened: boolean
  setOpened: (opened: boolean) => void
}

function SearchInput({ opened, setOpened }: Props) {
  const { height: viewportHeight } = useViewportSize()
  const [query, setQuery] = useState('')
  const combobox = useCombobox()

  const [debouncedQuery] = useDebouncedValue(query, 300)
  const { data: moviePropositions } = useMoviesSearch(debouncedQuery, 1, 5, {
    enabled: opened,
  })
  const { data: theaterPropositions } = useTheatersSearch(debouncedQuery, 1, 3)
  const [nbItems, setNbItems] = useState(10)
  const [movieOptions, setMovieOptions] = useState<React.ReactElement[]>([])
  const [theaterOptions, setTheaterOptions] = useState<React.ReactElement[]>([])
  const navigate = useNavigate()
  const openMovieModal = useCallback(
    (movie: Movie) => {
      trackEvent('movie_found', {
        id: movie.id,
        movieName: movie.titleVf,
        searchText: query,
      })
      // Open the movie modal with dynamic range activated
      navigate(`/film/${movie.urlTitle}-${movie.id}`, {
        state: { hasPrev: true },
      })
    },
    [query, navigate]
  )

  const openTheaterModal = useCallback(
    (theater: Theater) => {
      trackEvent('theater_found', {
        id: theater.id,
        theaterName: theater.name,
        theaterCity: theater.city,
        searchText: query,
      })
      navigate(`/cinema/${theater.urlTitle}-${theater.id}`, {
        state: { hasPrev: true },
      })
    },
    [query, navigate]
  )

  useEffect(() => {
    if (viewportHeight >= 500) {
      setNbItems(5)
    } else {
      const newNbItems = Math.floor(viewportHeight - 200) / 75
      setNbItems(Math.max(2, newNbItems))
    }
    setNbItems(10)
  }, [viewportHeight])

  // append movie propositions and theater propositions in a useEffect
  // to avoid a bug with the autocomplete component
  useEffect(() => {
    const elements = moviePropositions
      ? moviePropositions.map((item: Movie) => (
          <Combobox.Option value={`movie ${item.id}`} key={`movie ${item.id}`}>
            <AutoCompleteItem
              id={String(item.id)}
              movie={item}
              theater={null}
            />
          </Combobox.Option>
        ))
      : []
    setMovieOptions(elements.slice(0, nbItems))
  }, [moviePropositions])

  useEffect(() => {
    const elements = theaterPropositions
      ? theaterPropositions.map((item: Theater) => (
          <Combobox.Option
            value={`theater ${item.id}`}
            key={`theater ${item.id}`}
          >
            <AutoCompleteItem
              id={String(item.id)}
              movie={null}
              theater={item}
            />
          </Combobox.Option>
        ))
      : []
    setTheaterOptions(elements.slice(0, nbItems))
  }, [theaterPropositions])

  const myRef = useRef<HTMLInputElement | null>(null)
  return (
    <Combobox
      size="sm"
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(value: string) => {
        if (value.split(' ')[0] === 'movie') {
          const movieChoice = moviePropositions?.find(
            (movie) => String(movie.id) === value.split(' ')[1]
          )
          if (!movieChoice) {
            return
          }
          openMovieModal(movieChoice)
        } else if (value.split(' ')[0] === 'theater') {
          const theaterChoice = theaterPropositions?.find(
            (theater) => String(theater.id) === value.split(' ')[1]
          )
          if (!theaterChoice) {
            return
          }
          openTheaterModal(theaterChoice)
        }
        // Put the user input in the bar instead of the submitted item
        setQuery(debouncedQuery)
        myRef.current?.blur()
        setOpened(false)
      }}
    >
      <Combobox.Target>
        <TextInput
          placeholder="Chercher un film ou un cinéma"
          value={query}
          onChange={(event) => {
            setQuery(event.currentTarget.value)
            combobox.openDropdown()
            combobox.updateSelectedOptionIndex()
          }}
          onFocus={() => {
            combobox.openDropdown()
          }}
          onKeyDown={(e) => e.stopPropagation()}
        />
      </Combobox.Target>
      <Combobox.Dropdown mah={viewportHeight - 200} className="overflow-auto">
        <Combobox.Options className="m-2">
          <Combobox.Group label="Films">{movieOptions}</Combobox.Group>
          <Combobox.Group label="Cinémas">{theaterOptions}</Combobox.Group>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default React.memo(SearchInput)
