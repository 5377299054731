import { Box } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { memo, useCallback } from 'react'

import ShowtimeContext from 'types/ShowtimeContext'
import type { Movie, Showtime, Theater } from 'types/showtimes'

import trackEvent from 'queries/event'
import {
  showtimeIsAVP,
  showtimeIsUnavailable,
  showtimePriceText,
} from 'utils/showtimes'

import ShowtimeModal from 'components/showtimemodal/ShowtimeModal'

import ShowtimeBadgeTarget from './ShowtimeBadgeTarget'

type Props = {
  showtime: Showtime
  movie: Movie
  theater: Theater
  isSelected?: boolean
  showPrice?: boolean
}

function ShowtimeBadge({
  showtime,
  theater,
  isSelected = false,
  showPrice = false,
  movie,
}: Props) {
  const [opened, { open, close }] = useDisclosure(false)

  const isUnavailable = showtimeIsUnavailable(showtime.showtime)
  const isAvantPremiere = showtimeIsAVP(showtime.showtime, movie.releaseDate)
  const price = showPrice ? showtimePriceText(theater, showtime) : null

  const handleChange = useCallback(() => {
    trackEvent('showtime_click', {
      showtimeId: showtime.id,
      showtime: showtime.showtime,
      url: window.location.href,
    })
    open()
  }, [showtime, opened])

  const badge = (
    <ShowtimeBadgeTarget
      showtimeText={showtime.showtime.format('HH:mm')}
      vo={showtime.vo}
      audio={showtime.audio}
      subtitles={showtime.subtitles}
      is3d={showtime.is3d}
      extraInfo={showtime.extraInfo}
      isAvantPremiere={isAvantPremiere}
      price={price}
      showPrice={showPrice || false}
      isSelected={isSelected}
      isUnavailable={isUnavailable}
      isEvent={showtime.eventInfo !== null}
    />
  )

  if (isUnavailable || !opened) {
    return <Box onClick={handleChange}>{badge}</Box>
  }

  return (
    <>
      <Box onClick={handleChange}>{badge}</Box>
      <ShowtimeModal
        showtimeContext={new ShowtimeContext(showtime, movie, theater)}
        onClose={close}
        opened={opened}
      />
    </>
  )
}

export default memo(ShowtimeBadge)
