import { Button, Group, LoadingOverlay, Stack } from '@mantine/core'
import { memo } from 'react'
import { MdOutlineMovie } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { Share, Ticket } from 'tabler-icons-react'

import ShareButton from 'components/common/ShareButton'
import trackEvent from 'queries/event'
import { useShowtimeReservationUrl } from 'queries/showtimes'

function ShowtimeLinks({
  showtimeId,
  shareUrl,
  shareTitle,
  movieUrl,
  onClose,
}: {
  showtimeId: number
  shareUrl: string
  shareTitle: string
  movieUrl: string
  onClose: () => void
}) {
  const { data: reservationUrl, isLoading } =
    useShowtimeReservationUrl(showtimeId)

  return (
    <Stack className="w-full mt-2">
      {reservationUrl ? (
        <Button
          component="a"
          leftSection={<Ticket size={24} />}
          className="text-center text-zinc-100 bg-zinc-700 block grow-1"
          disabled={reservationUrl.length === 0}
          href={reservationUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackEvent('reservation_click', { showtimeId })}
        >
          Réserver
        </Button>
      ) : (
        <Button
          className="text-center text-zinc-600 bg-zinc-200 opacity-50"
          disabled
        >
          <LoadingOverlay visible={isLoading} overlayProps={{ blur: 2 }} />
          Réservation indisponible
        </Button>
      )}
      <Group justify="space-between">
        <ShareButton url={shareUrl} text={shareTitle}>
          <Button
            leftSection={<Share size={24} />}
            className="text-center text-zinc-800 bg-zinc-50 shadow-sm"
          >
            Partager
          </Button>
        </ShareButton>
        <Button
          component={Link}
          to={movieUrl}
          onClick={onClose}
          state={{ hasPrev: true }}
          leftSection={<MdOutlineMovie size={24} />}
          className="text-zinc-800 bg-zinc-50 shadow-sm text-center"
        >
          Page du film
        </Button>
      </Group>
    </Stack>
  )
}
export default memo(ShowtimeLinks)
