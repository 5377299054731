import type React from 'react'
import { useEffect } from 'react'

import trackEvent from 'queries/event'
import useDisplayStore from 'states/useDisplayStore'
import { globalFunc, globalVar } from 'utils/utils'

export default function useAppReviewRequest(
  transitionCount: React.MutableRefObject<number>
) {
  const { nbLogins, requestedAppReview, updateRequestedAppReview } =
    useDisplayStore((state) => ({
      nbLogins: state.nbLogins,
      requestedAppReview: state.requestedAppReview,
      updateRequestedAppReview: state.updateRequestedAppReview,
    }))

  useEffect(() => {
    // Only once, when we are back to the Search page
    if (transitionCount.current === 3) {
      // Ask user to review the app if they used the app for 4 days
      if (
        !requestedAppReview &&
        nbLogins >= 4 &&
        globalVar('requestAppReview')
      ) {
        trackEvent('app_review_request', { nb_logins: nbLogins })
        globalFunc('requestAppReview')
        updateRequestedAppReview(true)
      }
    }
  }, [transitionCount.current])
}
