import { omit } from 'lodash'

import type { SearchParams } from 'types/showtimes'
import { globalVar } from 'utils/utils'
import { apiPost } from './common'

type WindowKey = 'osName' | 'osVersion' | 'appVersion'

export interface Subscription {
  nb_logins: number
}

export default function notificationSubscribe(
  expoToken: string,
  searchParams: SearchParams,
  favoriteTheaterIds: number[],
  showPrices: boolean
): Promise<Subscription> | null {
  if (
    searchParams.latitude === undefined &&
    (searchParams.theaterIds === undefined ||
      searchParams.theaterIds.length === 0)
  ) {
    return null
  }
  if (searchParams.movieId !== undefined) {
    return null
  }
  const filteredParams: any = omit(searchParams, [
    'start',
    'end',
    'latitude',
    'longitude',
    'range',
    'movieId',
    'movieName',
    'theaterIds',
  ])
  if (showPrices) {
    filteredParams.showPrices = true
  }
  const windowKeys: WindowKey[] = ['osName', 'osVersion', 'appVersion']
  for (const key of windowKeys) {
    if (globalVar(key)) {
      filteredParams[key] = globalVar(key)
    }
  }
  const data = {
    expo_token: expoToken,
    latitude: searchParams.latitude,
    longitude: searchParams.longitude,
    range: searchParams.range,
    favorite_theaters_ids: favoriteTheaterIds,
    search_params: filteredParams,
  }
  return apiPost<Subscription>('/api/users/subscribe', data)()
}
