import { Accordion, Checkbox, Grid, Title } from '@mantine/core'
import { memo } from 'react'
import useSearchStore from 'states/useSearchStore'

const GENRES = [
  'Drame',
  'Comédie',
  'Animation',
  'Aventure',
  'Thriller',
  'Romance',
  'Policier',
  'Fantastique',
  'Action',
  'Science-fiction',
  'Horreur',
  'Documentaire',
  'Comédie musicale',
  'Western',
  'Fantasy',
  'Biopic',
  'Guerre',
]

function GenreSelect() {
  const genres = useSearchStore((state) => state.searchParams.genres)
  const excludeGenres = useSearchStore(
    (state) => state.searchParams.excludeGenres
  )
  const updateSearchParams = useSearchStore((state) => state.updateSearchParams)
  const nbSelected = (genres?.length || 0) + (excludeGenres?.length || 0)

  return (
    <Accordion.Item value="genres">
      <Accordion.Control>
        <Title order={5}>Genres {nbSelected > 0 && `(${nbSelected})`}</Title>
      </Accordion.Control>
      <Accordion.Panel>
        <Grid>
          {GENRES.sort().map((genre) => (
            <Grid.Col span={6} key={genre}>
              <Checkbox
                label={genre}
                checked={genres?.includes(genre) || false}
                indeterminate={excludeGenres?.includes(genre) || false}
                variant={genres?.includes(genre) ? 'filled' : 'outline'}
                onChange={() => {
                  if (excludeGenres?.includes(genre)) {
                    updateSearchParams({
                      excludeGenres: excludeGenres.filter((g) => g !== genre),
                    })
                  } else if (genres?.includes(genre)) {
                    updateSearchParams({
                      genres: genres.filter((g) => g !== genre),
                      excludeGenres: excludeGenres?.concat(genre) || [genre],
                    })
                  } else {
                    updateSearchParams({
                      genres: genres?.concat(genre) || [genre],
                    })
                  }
                }}
                color="zinc.6"
              />
            </Grid.Col>
          ))}
        </Grid>
      </Accordion.Panel>
    </Accordion.Item>
  )
}

export default memo(GenreSelect)
