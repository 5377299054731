import dayjs from 'dayjs'
import { omit, pick } from 'lodash'
import { useInfiniteQuery, useQuery } from 'react-query'

import {
  type MovieWithShowtimes,
  type SearchParams,
  type Theater,
  apiToMovieWithShowtimes,
} from 'types/showtimes'
import { RANGES } from 'utils/utils'
import apiFetch from './common'
import { movieById } from './movies'

export const PAGE_SIZE = 12

async function showtimeReservationUrl(showtimeId: number) {
  const { url } = await apiFetch<{ url: string }>(
    `/api/showtimes/${showtimeId}/reservation`
  )()
  return url
}

export function useShowtimeReservationUrl(showtimeId: number, enabled = true) {
  return useQuery(
    ['reservation', showtimeId],
    () => showtimeReservationUrl(showtimeId),
    { enabled: enabled && !!showtimeId }
  )
}

async function searchShowtimes(
  searchParams: SearchParams,
  page: number,
  pageSize: number
): Promise<MovieWithShowtimes[]> {
  if (
    searchParams.theaterIds === undefined &&
    (searchParams.latitude === undefined ||
      searchParams.longitude === undefined)
  ) {
    throw new Error('Missing latitude, longitude or theaterIds')
  }

  const start = searchParams.start || dayjs().subtract(10, 'minutes')
  const end = searchParams.end || dayjs().add(7, 'days').startOf('day')
  const params: any = { ...searchParams, start, end, page, pageSize }
  params.langs?.forEach((lang: string) => {
    params[lang] = true
  })

  const data = await apiFetch<any>('/api/showtimes/search', params)()
  return data.map(apiToMovieWithShowtimes)
}

export function useSearchShowtimes(
  searchParams: SearchParams,
  pageSize: number
) {
  return useInfiniteQuery({
    queryKey: ['showtimes', searchParams],
    queryFn: ({ pageParam = 1 }) =>
      searchShowtimes(searchParams, pageParam, pageSize),
    getNextPageParam: (
      lastPage: MovieWithShowtimes[],
      allPages: MovieWithShowtimes[][]
    ) => (lastPage.length === pageSize ? allPages.length + 1 : undefined),
  })
}

async function movieShowtimes(
  searchParams: SearchParams,
  movieId: string | number | undefined
): Promise<MovieWithShowtimes> {
  if (!movieId) {
    throw new Error('Missing movieId')
  }

  const data = await searchShowtimes({ ...searchParams, movieId }, 1, 1)

  if (data.length > 0) {
    return data[0]
  }

  // Fetch directly the movie's info if there are no showtimes
  const movie = await movieById(movieId)
  return { movie, theaters: [] }
}

export function useMovieShowtimes(
  searchParams: SearchParams,
  movieId: string | number | undefined
) {
  // Omit search parameter that are filtering the type of movie, as the movie is already selected
  const filteredSearchParams = omit(searchParams, [
    'releasedBefore',
    'releasedAfter',
    'forChildren',
    'minDuration',
    'maxDuration',
  ])
  return useQuery<MovieWithShowtimes>(
    ['movieShowtimes', movieId?.toString(), filteredSearchParams],
    () => movieShowtimes(filteredSearchParams, movieId),
    { enabled: !!movieId }
  )
}

export function useMovieAllTheaters(
  searchParams: SearchParams,
  movieId: string | number | undefined
) {
  const relevantSearchParams = {
    ...pick(searchParams, ['latitude', 'longitude', 'range', 'theaterIds']),
    start: dayjs().startOf('day'),
    end: dayjs().add(6, 'days').endOf('day'),
  }
  return useQuery<Theater[]>(
    ['movieAllTheaters', movieId, relevantSearchParams],
    () =>
      searchShowtimes({ ...relevantSearchParams, movieId }, 1, 1).then(
        (movies) => movies[0]?.theaters || []
      )
  )
}

async function theaterShowtimes(
  theaterId: number | undefined
): Promise<MovieWithShowtimes[]> {
  if (!theaterId) {
    throw new Error('Missing theaterId')
  }

  const pageSize = 10
  const allMovies: MovieWithShowtimes[] = []
  let currentPage = 1

  while (allMovies.length === pageSize * (currentPage - 1)) {
    // disable eslint await inside loop
    // eslint-disable-next-line no-await-in-loop
    const data = await searchShowtimes(
      { theaterIds: [theaterId], start: dayjs().startOf('day') },
      currentPage,
      pageSize
    )
    allMovies.push(...data)
    currentPage += 1
  }
  return allMovies
}

export function useTheaterShowtimes(theaterId: number | undefined) {
  return useQuery<MovieWithShowtimes[]>(
    ['theaterShowtimes', theaterId],
    () => theaterShowtimes(theaterId),
    { enabled: !!theaterId }
  )
}

export async function bestRange(
  latitude: number,
  longitude: number,
  movieId?: string
) {
  const params: any = { latitude, longitude }
  if (movieId) {
    params.movie_id = movieId
  }
  const { best_range: range } = await apiFetch<{ best_range: number | null }>(
    '/api/showtimes/best_range',
    params
  )()
  if (range === null) {
    return null
  }
  return RANGES.find((val) => val >= range)
}

async function getShowtime(showtimeId: string | undefined) {
  if (!showtimeId) {
    throw new Error('Missing showtimeId')
  }
  const data = await apiFetch<any>(`/api/showtimes/${showtimeId}`)()
  return apiToMovieWithShowtimes(data)
}

export function useShowtime(showtimeId: string | undefined) {
  return useQuery(['showtime', showtimeId], () => getShowtime(showtimeId), {
    enabled: !!showtimeId,
  })
}
