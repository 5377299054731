import { Grid, Group, Stack } from '@mantine/core'
import { Link } from 'react-router-dom'

import ShowtimeBadge from 'components/search/moviemodal/ShowtimeBadge'
import MovieDetails from 'components/search/movieposter/MovieDetails'
import MovieImage from 'components/search/movieposter/MovieImage'
import { useIsMobile } from 'providers/isMobileProvider'
import useSearchStore from 'states/useSearchStore'
import type { Movie, Showtime, Theater } from 'types/showtimes'

type Props = {
  movie: Movie
  theater: Theater
  showtimes: Showtime[]
}

export default function TheaterMovieCard({ movie, showtimes, theater }: Props) {
  const { isMobile } = useIsMobile()
  const showPrices = useSearchStore((state) => state.showPrices)
  const movieUrl = `/film/${movie.urlTitle}-${movie.id}`
  const keyCount: { [key: string]: number } = {}
  return (
    <Grid key={movie.id}>
      <Grid.Col span={isMobile ? 5 : 3}>
        <Link to={movieUrl} state={{ hasPrev: true }}>
          <MovieImage movie={movie} />
        </Link>
      </Grid.Col>
      <Grid.Col span={isMobile ? 7 : 9}>
        <Stack gap="sm">
          <Link to={movieUrl} state={{ hasPrev: true }}>
            <MovieDetails movie={movie} titleLineClamp={2} />
          </Link>

          <Group gap="md" className="flex leading-6 pt-1">
            {showtimes.map((showtime) => {
              const time = showtime.showtime.format('HH:mm')
              keyCount[time] = (keyCount[time] || 0) + 1
              return (
                <ShowtimeBadge
                  movie={movie}
                  theater={theater}
                  showtime={showtime}
                  showPrice={showPrices}
                  key={`${time}-${keyCount[time]}`}
                />
              )
            })}
          </Group>
        </Stack>
      </Grid.Col>
    </Grid>
  )
}
