import {
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import MobileModalHeader from 'components/search/genericmodal/MobileModalHeader'
import ModalHeader from 'components/search/genericmodal/ModalHeader'
import ShowtimesTab from 'components/search/moviemodal/ShowtimesTab'
import type dayjs from 'dayjs'
import { useIsMobile } from 'providers/isMobileProvider'
import { useTheaterShowtimes } from 'queries/showtimes'
import useTheaterById from 'queries/theaters'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import type { MovieWithShowtimes, Theater } from 'types/showtimes'

import Loader from 'components/common/Loader'

import PageTabs from 'components/common/PageTabs'
import useNavigateBack from 'hooks/useNavigateBack'
import { BsTicketPerforated, BsTicketPerforatedFill } from 'react-icons/bs'
import {
  HiBuildingStorefront,
  HiOutlineBuildingStorefront,
} from 'react-icons/hi2'

import Favorite from './Favorite'
import TheaterDescription from './TheaterDescription'
import TheaterMovieList from './TheaterMovieList'

function checkDateHasShowtimes(movies: MovieWithShowtimes[]) {
  return (date: dayjs.Dayjs) =>
    movies.some((resp: MovieWithShowtimes) =>
      resp.theaters.some(({ showtimes }) =>
        showtimes.some(({ showtime }) => showtime.isSame(date.utc(), 'day'))
      )
    )
}

type RouteParams = {
  theaterId: string
  tab: string
}

function TheaterContent({
  theater,
  currentTab,
}: {
  theater: Theater
  currentTab: string | undefined
}) {
  const location = useLocation()
  const { isMobile } = useIsMobile()
  const { data: results, isLoading } = useTheaterShowtimes(theater.id)
  const theaterUrl = `/cinema/${theater.urlTitle}-${theater.id}`

  const movies = results || []
  const helmet = (
    <Helmet>
      <title>{theater.name} - Programme</title>
      <meta
        name="description"
        content={`Séances de films au programme du cinéma ${theater.name}`}
      />
      <meta property="og:title" content={`${theater.name} - Programme`} />
      <meta
        property="og:description"
        content={`Séances de films au programme du cinéma ${theater.name}`}
      />
      <meta
        property="og:url"
        content={`${window.location.origin}/${theaterUrl}`}
      />
      <link rel="canonical" href={`${window.location.origin}/${theaterUrl}`} />
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
        integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
        crossOrigin=""
      />
      <script
        src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
        integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo="
        crossOrigin=""
      />
    </Helmet>
  )

  const showtimesTab = (
    <ShowtimesTab
      selectedDateFilter={checkDateHasShowtimes(movies)}
      dateFilter={checkDateHasShowtimes(movies)}
    >
      <ScrollArea offsetScrollbars className="h-full w-full" type="auto">
        <TheaterMovieList theater={theater} movies={movies} />
      </ScrollArea>
    </ShowtimesTab>
  )
  const mobileHeader = (
    <>
      <MobileModalHeader className="mb-2">
        <Favorite theater={theater} size={25} />
      </MobileModalHeader>
      <Text
        c="zinc.9"
        className="font-semibold text-2xl !uppercase truncate"
        style={{
          fontFamily: 'Josefin Sans, sans-serif',
        }}
      >
        {theater.name}
      </Text>
    </>
  )

  const showtimesContent = (
    <Flex
      direction="column"
      gap={1}
      className="w-full h-full p-[10px] text-zinc-900"
    >
      {mobileHeader}
      {showtimesTab}
    </Flex>
  )

  const keyTabs = [
    {
      subroute: 'info',
      title: 'Informations',
      icon: <HiOutlineBuildingStorefront size={30} className="mx-auto" />,
      selectedIcon: <HiBuildingStorefront size={30} className="mx-auto" />,
      content: (
        <Flex direction="column" gap={1} className="h-full p-[10px]">
          {mobileHeader}
          <TheaterDescription theater={theater} />
        </Flex>
      ),
    },
    {
      subroute: undefined,
      title: `A l'affiche`,
      icon: <BsTicketPerforated size={30} className="mx-auto" />,
      selectedIcon: <BsTicketPerforatedFill size={30} className="mx-auto" />,
      content: showtimesContent,
    },
  ]
  if (isMobile) {
    return (
      <Flex className="h-[var(--page-height)] bg-white">
        {helmet}
        <PageTabs root={theaterUrl} tabs={keyTabs} currentTab={currentTab} />
      </Flex>
    )
  }

  if (currentTab !== undefined) {
    return (
      <Navigate
        to={theaterUrl}
        state={{ hasPrev: location.state?.hasPrev }}
        replace
      />
    )
  }

  return (
    <Flex
      direction="column"
      className="bg-white h-[calc(100*var(--vh)-var(--header-height))] overflow-hidden"
    >
      {helmet}
      <div className="flex-none">
        <ModalHeader
          className="mt-2"
          linkTitle={theater.name || ''}
          url={`${window.location.origin}/${theaterUrl}`}
        >
          <Group className="flex flex-nowrap">
            <Title
              order={1}
              lineClamp={2}
              fw={300}
              className="text-3xl mr-auto font-light"
            >
              {theater.name}
            </Title>
            <Favorite theater={theater} size={25} />
          </Group>
        </ModalHeader>
      </div>
      <Flex direction="column" className="flex-1 overflow-hidden mx-auto">
        <Grid
          columns={24}
          gutter={0}
          grow
          className="max-w-6xl w-full flex-1 h-full"
          classNames={{ inner: 'h-full' }}
          m={0}
        >
          <Grid.Col className="h-full overflow-y-auto" span={12}>
            <TheaterDescription theater={theater} />
          </Grid.Col>
          <Grid.Col span={11} offset={1} className="overflow-y-auto h-full">
            <Stack className="h-full relative">
              <>
                <LoadingOverlay
                  visible={isLoading}
                  overlayProps={{ blur: 2 }}
                />
                {showtimesTab}
              </>
            </Stack>
          </Grid.Col>
        </Grid>
      </Flex>
    </Flex>
  )
}

export default function TheaterPage() {
  const { theaterId, tab } = useParams<RouteParams>()
  const navigateBack = useNavigateBack()
  const { data: theater, isError } = useTheaterById(Number(theaterId))
  useEffect(() => {
    if (isError) {
      navigateBack()
    }
  }, [isError, navigateBack])

  if (!theater) {
    return <Loader />
  }
  return <TheaterContent theater={theater} currentTab={tab} />
}
