import { Select } from '@mantine/core'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { memo } from 'react'
import { DEFAULT_DATE_TEXT } from 'types/showtimes'

import useSearchStore from 'states/useSearchStore'

type Props = {
  withoutBorder?: boolean
}

const getPossibleDates = () => {
  const dates = [
    { value: '-1', label: 'Maintenant' },
    { value: '0', label: "Aujourd'hui" },
    { value: '1', label: 'Demain' },
  ]
  for (let days = 2; days < 7; days += 1) {
    const dayOfWeek = dayjs().locale('fr').add(days, 'days').format('dddd')
    dates.push({
      value: days.toString(),
      label: dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1),
    })
  }
  return dates
}
export const possibleDates = getPossibleDates()

function DateSelect({ withoutBorder = false }: Props) {
  const dateTimeParams = useSearchStore((state) => state.dateTimeParams)
  const day = dateTimeParams.day === undefined ? null : dateTimeParams.day
  const updateDateTimeParams = useSearchStore(
    (state) => state.updateDateTimeParams
  )

  return (
    <Select
      size="md"
      checkIconPosition="right"
      placeholder={DEFAULT_DATE_TEXT}
      data={possibleDates}
      value={day}
      comboboxProps={{ withinPortal: false }}
      onChange={(newDay) =>
        updateDateTimeParams({ day: newDay === null ? undefined : newDay })
      }
      clearable={!withoutBorder}
      className={`capitalize ${
        withoutBorder ? '' : 'border border-zinc-300 rounded-md'
      }`}
      styles={{
        input: {
          borderColor: 'transparent',
          _focus: { borderColor: 'transparent' },
        },
      }}
    />
  )
}

export default memo(DateSelect)
