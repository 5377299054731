import type dayjs from 'dayjs'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

interface IState {
  currentDate: dayjs.Dayjs | undefined
  requestedAppReview: boolean
  requestedInstallOrUpgrade: boolean
  requestedNotificationsPermission: boolean
  nbLogins: number
  updateCurrentDate: (date: dayjs.Dayjs | undefined) => void
  updateRequestedAppReview: (requested: boolean) => void
  updateRequestedInstallOrUpgrade: (requested: boolean) => void
  updateRequestedNotificationsPermission: (requested: boolean) => void
  setNbLogins: (nbLogins: number) => void
}

const useDisplayStore = create<IState>()(
  devtools(
    persist(
      (set) => ({
        currentDate: undefined,
        requestedAppReview: false,
        requestedInstallOrUpgrade: false,
        requestedNotificationsPermission: false,
        nbLogins: 0,
        updateCurrentDate: (currentDate: dayjs.Dayjs | undefined) =>
          set({ currentDate }),
        updateRequestedAppReview: (requestedAppReview: boolean) =>
          set({ requestedAppReview }),
        updateRequestedInstallOrUpgrade: (requestedInstallOrUpgrade: boolean) =>
          set({ requestedInstallOrUpgrade }),
        updateRequestedNotificationsPermission: (
          requestedNotificationsPermission: boolean
        ) => set({ requestedNotificationsPermission }),
        setNbLogins: (nbLogins: number) => set({ nbLogins }),
      }),
      {
        name: 'display',
        getStorage: () => localStorage,
      }
    )
  )
)

export default useDisplayStore
