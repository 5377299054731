import useSearchStore from 'states/useSearchStore'

function useFiltersCount() {
  const releaseMood = useSearchStore((state) => state.releaseMood)
  const langs = useSearchStore((state) => state.searchParams.langs)
  const ageFilter = useSearchStore((state) => state.ageFilter)
  const cards = useSearchStore((state) => state.searchParams.cards)
  const genres = useSearchStore((state) => state.searchParams.genres)
  const excludeGenres = useSearchStore(
    (state) => state.searchParams.excludeGenres
  )
  const minDuration = useSearchStore((state) => state.searchParams.minDuration)
  const maxDuration = useSearchStore((state) => state.searchParams.maxDuration)

  return (
    (cards ? cards.length : 0) +
    (langs ? langs.length : 0) +
    Number(ageFilter !== 'all') +
    Number(releaseMood != null) +
    (genres ? genres.length : 0) +
    (excludeGenres ? excludeGenres.length : 0) +
    Number(minDuration != null) +
    Number(maxDuration != null)
  )
}

export default useFiltersCount
