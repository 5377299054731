import { Alert } from '@mantine/core'
import { useEffect, useState } from 'react'
import { IconContext } from 'react-icons'
import { FaHeartBroken, FaRegHeart } from 'react-icons/fa'
import useSearchStore from 'states/useSearchStore'

function EmptyFavoriteNotification() {
  const emptyFavoriteError = useSearchStore((state) => state.emptyFavoriteError)
  const [notification, setNotification] = useState(false)
  const unapplySearchFavoriteTheaters = useSearchStore(
    (state) => state.unapplySearchFavoriteTheaters
  )

  useEffect(() => {
    if (emptyFavoriteError) {
      setNotification(true)
      unapplySearchFavoriteTheaters()
      setTimeout(() => setNotification(false), 5000)
    }
  }, [emptyFavoriteError, unapplySearchFavoriteTheaters, setNotification])
  if (notification) {
    return (
      <div className="z-[100] notification-popup absolute bottom-5 right-[5%] max-w-[80%] shadow-md">
        <Alert
          icon={<FaHeartBroken size="1rem" />}
          title="Pas de cinémas favoris à afficher !"
          color="red"
        >
          <p>Recherche dans tous les cinémas autour de vous.</p> <br />
          <p className="align-middle">
            <span className="pr-1">Cliquez sur </span>
            {
              // eslint-disable-next-line react/jsx-no-constructed-context-values
              <IconContext.Provider value={{ className: 'inline' }}>
                <span className="text-zinc-500">
                  <FaRegHeart />
                </span>
              </IconContext.Provider>
            }
            <span className="pl-1">
              {' '}
              à côté du nom d&apos;un cinéma pour l&apos;ajouter à vos favoris.
            </span>
          </p>
        </Alert>
      </div>
    )
  }
  return null
}

export default EmptyFavoriteNotification
