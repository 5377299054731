import { Image } from '@mantine/core'
import { useCallback } from 'react'

import { useIsMobile } from 'providers/isMobileProvider'
import trackEvent from 'queries/event'

type Props = {
  url: string
  imgSrc: string
  imgAlt: string
  scale?: number
  aspectRatio: number
  badgeType: 'ios' | 'android'
}

function GenericBadge({
  url,
  imgSrc,
  imgAlt,
  scale,
  aspectRatio,
  badgeType,
}: Props) {
  const height = 50 * Math.max(scale || 1, 1)
  const width = height * aspectRatio

  // Prevent displaying the badge inside the mobile app
  const { isMobileApp, isIos } = useIsMobile()

  // When the screen is too small, we only display the most relevant badge
  const tooSmall = window.innerWidth < 420 && window.innerHeight < 700
  const badgeSacrificed = tooSmall && (badgeType === 'ios' ? !isIos : isIos)

  const trackClick = useCallback(() => {
    trackEvent('app_badge_click', { website: badgeType })
  }, [badgeType])

  return isMobileApp || badgeSacrificed ? null : (
    <div className={`w-[${width}px]`}>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={trackClick}
      >
        <Image src={imgSrc} w={width} h={height} alt={imgAlt} />
      </a>
    </div>
  )
}

GenericBadge.defaultProps = {
  scale: 1,
}

export default GenericBadge
