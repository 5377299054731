import { Accordion, Checkbox, Grid, Stack, Title } from '@mantine/core'
import useSearchStore from 'states/useSearchStore'

const CARDS = {
  France: [
    'UGC illimité',
    'CinéPass',
    'Chèque Cinéma Universel',
    'Cinéchèques',
    'Ciné Carte CIP',
  ],
  Belgique: ['UGC unlimited', 'Pass Cineville'],
}

export default function CardSelect() {
  const selectedCards = useSearchStore((state) => state.searchParams.cards)
  const updateSearchParams = useSearchStore((state) => state.updateSearchParams)

  return (
    <Accordion.Item value="card">
      <Accordion.Control>
        <Title order={5}>
          Carte d&apos;abonnement{' '}
          {selectedCards &&
            selectedCards.length > 0 &&
            `(${selectedCards.length})`}
        </Title>
      </Accordion.Control>
      <Accordion.Panel>
        {Object.entries(CARDS).map(([country, cards]) => (
          <Stack key={country} className="ml-3 mb-4">
            <Title order={6}>{country}</Title>
            <Grid>
              {cards.map((card) => (
                <Grid.Col span={6} key={card}>
                  <Checkbox
                    label={card}
                    checked={selectedCards?.includes(card)}
                    onChange={() => {
                      if (selectedCards?.includes(card)) {
                        updateSearchParams({
                          cards: selectedCards.filter((c) => c !== card),
                        })
                      } else {
                        updateSearchParams({
                          cards: selectedCards
                            ? selectedCards.concat(card)
                            : [card],
                        })
                      }
                    }}
                    color="zinc.6"
                  />
                </Grid.Col>
              ))}
            </Grid>
          </Stack>
        ))}
      </Accordion.Panel>
    </Accordion.Item>
  )
}
