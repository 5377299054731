import { Center, Container, Grid, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { Search } from 'tabler-icons-react'

import Logo from 'components/common/Logo'
import useFiltersCount from 'hooks/useFiltersCount'

import DateTimeInput from './DateTimeInput'
import LocationInput from './LocationInput'
import MobileButton from './MobileButton'
import OtherOptionsButton from './OtherOptionsButton'
import RangeSelect from './RangeSelect'
import SearchInput from './SearchInput'

type Props = {
  onToggleModals: (value: boolean) => void
  OtherOptionsState: { opened: boolean; toggle: () => void }
}

function SearchBar({ onToggleModals, OtherOptionsState }: Props) {
  const [openedMovieInput, setOpenedMovieInput] = useState(false)
  const filtersCount = useFiltersCount()
  const { opened: openedOther, toggle: toggleOther } = OtherOptionsState
  useEffect(() => {
    onToggleModals(openedMovieInput || openedOther)
  }, [openedMovieInput, openedOther, onToggleModals])

  return (
    <div className="bg-white">
      <div className="h-[87px]">
        <Logo />
        <Container
          px={0}
          fluid
          className="w-full box-border pt-1 pl-[max(50px,10%)] pr-[max(10px,5%)]"
        >
          <div className="rounded-2xl max-w-[1000px] mx-auto pt-4">
            <Grid
              className="border-zinc-200 pr-[5%]"
              justify="center"
              columns={24}
              align="stretch"
            >
              <Grid.Col span={2} className="h-16 py-4">
                <Center>
                  <MobileButton
                    tooltipText="Chercher un film / cinéma"
                    opened={openedMovieInput}
                    setOpened={setOpenedMovieInput}
                    icon={<Search size="32" />}
                    width="400px"
                    color="text-zinc-700"
                  >
                    <SearchInput
                      opened={openedMovieInput}
                      setOpened={setOpenedMovieInput}
                    />
                  </MobileButton>
                </Center>
              </Grid.Col>
              <Grid.Col
                span={7}
                offset={1}
                className="h-16 bg-white rounded-l-[50px] border-y border-l"
              >
                <Center className="h-full my-auto">
                  <LocationInput />
                </Center>
              </Grid.Col>
              <Grid.Col span={4} className="bg-white border-y align-middle">
                <Center className="h-full my-auto">
                  <RangeSelect />
                </Center>
              </Grid.Col>
              <Grid.Col span={7} className="h-16 border-l border-y bg-white">
                <Center className="h-full my-auto">
                  <DateTimeInput />
                </Center>
              </Grid.Col>
              <Grid.Col
                span={2}
                className="relative h-16 pt-3 border rounded-r-[50px] bg-white"
              >
                <Center>
                  <OtherOptionsButton toggle={toggleOther} />
                </Center>
                <div className="absolute top-[0px] right-[-2px]">
                  <Text
                    color="white"
                    className={`cursor-pointer transition-all ease-in duration-300 text-center w-6 px-2 rounded-2xl font-bold ${
                      filtersCount > 0
                        ? 'bg-zinc-700 opacity-80'
                        : 'opacity-0 bg-white text-white invisible'
                    }`}
                    size="xs"
                  >
                    {filtersCount > 0 ? filtersCount : 1}
                  </Text>{' '}
                </div>
              </Grid.Col>
            </Grid>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default React.memo(SearchBar)
