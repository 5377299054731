import { Accordion, Button, Grid, Group, Space } from '@mantine/core'
import { useIsMobile } from 'providers/isMobileProvider'
import { memo } from 'react'

import CardSelect from './CardSelect'
import DurationSelect from './DurationSelect'
import GenreSelect from './GenreSelect'
import LangSelect from './LangSelect'
import MoodSelect from './MoodSelect'
import OtherOptionsReset from './OtherOptionsReset'

function OtherOptions({ onClose }: { onClose: () => void }) {
  const { isMobile } = useIsMobile()

  return (
    <div className="h-[calc(var(--page-height)-1vh)] flex flex-col bg-white overflow-hidden">
      <div className="flex-1 overflow-auto">
        <Space h={isMobile ? 10 : 15} />
        <Grid className="px-4 w-full max-w-5xl mx-auto bg-white" gutter="xl">
          <Grid.Col span={isMobile ? 12 : 6}>
            <LangSelect />
          </Grid.Col>
          <Grid.Col span={isMobile ? 12 : 6}>
            <MoodSelect />
          </Grid.Col>
          <Grid.Col span={12}>
            <DurationSelect />
          </Grid.Col>
          <Grid.Col span={12} className="px-0">
            <Accordion variant="filled" className="select-none">
              <GenreSelect />
              <CardSelect />
            </Accordion>
          </Grid.Col>
        </Grid>
      </div>
      <div className="flex-none border-t">
        <Grid>
          <Grid.Col span={12} className="my-4">
            <Group>
              <OtherOptionsReset />
              <div className="mx-auto">
                <Button variant="outline" color="zinc.9" onClick={onClose}>
                  Fermer
                </Button>
              </div>
            </Group>
          </Grid.Col>
        </Grid>
      </div>
    </div>
  )
}

export default memo(OtherOptions)
