import { ActionIcon, Popover, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import React, { useCallback } from 'react'
import { BiSolidInfoCircle } from 'react-icons/bi'

type Props = {
  bubbleText: string
  className?: string
  dropdownClassName?: string
}

function InfoBubble({
  bubbleText,
  className = '',
  dropdownClassName = '',
}: Props) {
  const [opened, { close, open, toggle }] = useDisclosure(false)
  const handleClick = useCallback(
    (event: any) => {
      // Prevent the click from propagating to the parent
      event.stopPropagation()
      toggle()
    },
    [toggle]
  )

  return (
    <Popover
      position="bottom"
      offset={-2}
      withArrow
      shadow="lg"
      opened={opened}
    >
      <Popover.Target>
        <ActionIcon
          variant="transparent"
          className="mb-0.5 min-w-0 w-fit z-20"
          onMouseEnter={open}
          onMouseLeave={close}
          onClick={handleClick}
        >
          <div className={`rounded-full bg-white ${className}`}>
            <BiSolidInfoCircle size={20} className="rounded-full" />
          </div>
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown
        className={`max-w-[200px] text-center border-2 ${dropdownClassName}`}
      >
        <Text>{bubbleText}</Text>
      </Popover.Dropdown>
    </Popover>
  )
}

export default React.memo(InfoBubble)
