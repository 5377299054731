import { Group, Popover, Text } from '@mantine/core'
import { memo } from 'react'
import useSearchStore from 'states/useSearchStore'
import { CircleDotted } from 'tabler-icons-react'

import RangeSlider from './RangeSlider'

function RangeSelect() {
  const range = useSearchStore((state) => state.searchParams.range)
  const searchForFavoriteTheaters = useSearchStore(
    (state) => state.searchForFavoriteTheaters
  )

  return (
    <Popover
      width={300}
      position="bottom"
      withinPortal={false}
      withArrow
      disabled={searchForFavoriteTheaters}
    >
      <Popover.Target>
        <Group
          gap="xs"
          justify="center"
          className={
            searchForFavoriteTheaters
              ? 'cursor-default text-zinc-300'
              : 'cursor-pointer'
          }
        >
          <CircleDotted className="mb-0.5" />
          <Text>{searchForFavoriteTheaters ? '--' : range} km</Text>
        </Group>
      </Popover.Target>
      <Popover.Dropdown>
        <RangeSlider />
      </Popover.Dropdown>
    </Popover>
  )
}

export default memo(RangeSelect)
