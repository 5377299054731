import { AppShell, Container, Space, Text, Title } from '@mantine/core'
import Footer from 'components/common/Footer'
import LogoFull from 'components/common/LogoFull'
import { Helmet } from 'react-helmet'

function Privacy() {
  return (
    <AppShell className="bg-zinc-100" header={{ height: 87 }}>
      <AppShell.Header h="87px" className="shadow-md bg-white pt-5 bg-zinc-900">
        <LogoFull />{' '}
      </AppShell.Header>
      <Helmet>
        <title>Politique de confidentialité - La Bobine</title>
        <meta property="og:title" content="Politique de confidentialité" />
        <meta property="og:url" content={`${window.location.href}/privacy`} />
      </Helmet>
      <Container className="w-full bg-white shadow-xl" px="xs">
        <Space h="xl" />
        <Title order={2} className="text-center">
          Politique de confidentialité
        </Title>
        <Space h="xl" />
        <Text size="sm" className="text-center">
          Dernière mise à jour : 16 septembre 2022
        </Text>
        <Space h="xl" />
        <Container className="text-justify px-10">
          <Text size="sm">
            Le site web https://bobine.art est détenu par La Bobine, qui est un
            contrôleur de données de vos données personnelles.
          </Text>
          <Space h="sm" />
          <Text size="sm">
            Nous avons adopté cette politique de confidentialité, qui détermine
            la manière dont nous traitons les informations collectées par
            https://bobine.art, qui fournit également les raisons pour
            lesquelles nous devons collecter certaines données personnelles vous
            concernant. Par conséquent, vous devez lire cette politique de
            confidentialité avant d&apos;utiliser le site web de
            https://bobine.art.
          </Text>
          <Space h="sm" />
          <Text size="sm">
            Nous prenons soin de vos données personnelles et nous nous engageons
            à en garantir la confidentialité et la sécurité.
          </Text>
          <Space h="xl" />
          <Text className="font-bold">
            Les informations personnelles que nous collectons :
          </Text>
          <Space h="sm" />
          <Text size="sm">
            Lorsque vous visitez le https://bobine.art, nous recueillons
            automatiquement certaines informations sur votre appareil, notamment
            des informations sur votre navigateur web, votre adresse IP, votre
            fuseau horaire et certains des cookies installés sur votre appareil.
            En outre, lorsque vous naviguez sur le site, nous recueillons des
            informations sur les pages web ou les produits individuels que vous
            consultez, sur les sites web ou les termes de recherche qui vous ont
            renvoyé au site et sur la manière dont vous interagissez avec le
            site. Nous désignons ces informations collectées automatiquement par
            le terme &quot;informations sur les appareils&quot;.
          </Text>
          <Space h="xl" />
          <Text className="font-bold">
            Pourquoi traitons-nous vos données ?
          </Text>
          <Space h="sm" />
          <Text size="sm">
            Notre priorité absolue est la sécurité des données des clients et, à
            ce titre, nous ne pouvons traiter que des données minimales sur les
            utilisateurs, uniquement dans la mesure où cela est absolument
            nécessaire pour maintenir le site web. Les informations collectées
            automatiquement sont utilisées uniquement pour identifier les cas
            potentiels d&apos;abus et établir des informations statistiques
            concernant l&apos;utilisation du site web. Ces informations
            statistiques ne sont pas autrement agrégées de manière à identifier
            un utilisateur particulier du système.
          </Text>
          <Space h="sm" />
          <Text size="sm">
            Vous pouvez visiter le site web sans nous dire qui vous êtes ni
            révéler d&aposinformations, par lesquelles quelqu&aposun pourrait
            vous identifier comme un individu spécifique et identifiable.
            Toutefois, si vous souhaitez utiliser certaines fonctionnalités du
            site web vous pouvez nous fournir des données personnelles telles
            que votre position. Vous pouvez choisir de ne pas nous fournir vos
            données personnelles, mais il se peut alors que vous ne puissiez pas
            profiter de certaines fonctionnalités du site web. Les utilisateurs
            qui ne savent pas quelles informations sont obligatoires sont
            invités à nous contacter via contact@bobine.art.
          </Text>
          <Space h="xl" />
          <Text className="font-bold">Vos droits :</Text>
          <Space h="sm" />
          <Text size="sm">
            Si vous êtes un résident européen, vous disposez des droits suivants
            liés à vos données personnelles :
          </Text>
          <Space h="sm" />
          <Text size="sm">&bull; Le droit d&apos;être informé.</Text>
          <Text size="sm">&bull; Le droit d&apos;accès.</Text>
          <Text size="sm">&bull; Le droit de rectification.</Text>
          <Text size="sm">&bull; Le droit à l&apos;effacement.</Text>
          <Text size="sm">&bull; Le droit de restreindre le traitement.</Text>
          <Text size="sm">&bull; Le droit à la portabilité des données.</Text>
          <Text size="sm">&bull; Le droit d&apos;opposition.</Text>
          <Text size="sm">
            &bull; Les droits relatifs à la prise de décision automatisée et au
            profilage.
          </Text>
          <Space h="sm" />
          <Text size="sm">
            Si vous souhaitez exercer ce droit, veuillez nous contacter via les
            coordonnées ci-dessous.
          </Text>
          <Space h="sm" />
          <Text size="sm">
            En outre, si vous êtes un résident européen, veuillez noter que vos
            informations pourraient être transférées en dehors de l&apos;Europe,
            y compris au Canada et aux États-Unis.
          </Text>
          <Space h="xl" />
          <Text className="font-bold">
            Liens vers d&apos;autres sites web :
          </Text>
          <Space h="sm" />
          <Text size="sm">
            Notre site web peut contenir des liens vers d&apos;autres sites web
            qui ne sont pas détenus ou contrôlés par nous. Sachez que nous ne
            sommes pas responsables de ces autres sites web ou des pratiques de
            confidentialité des tiers. Nous vous encourageons à être attentif
            lorsque vous quittez notre site web et à lire les déclarations de
            confidentialité de chaque site web susceptible de collecter des
            informations personnelles.
          </Text>
          <Space h="xl" />
          <Text className="font-bold">Sécurité de l&apos;information :</Text>
          <Space h="sm" />
          <Text size="sm">
            Nous sécurisons les informations que vous fournissez sur des
            serveurs informatiques dans un environnement contrôlé et sécurisé,
            protégé contre tout accès, utilisation ou divulgation non autorisés.
            Nous conservons des garanties administratives, techniques et
            physiques raisonnables pour nous protéger contre tout accès,
            utilisation, modification et divulgation non autorisés des données
            personnelles sous son contrôle et sa garde. Toutefois, aucune
            transmission de données sur Internet ou sur un réseau sans fil ne
            peut être garantie.
          </Text>
          <Space h="xl" />
          <Text className="font-bold">Divulgation légale :</Text>
          <Space h="sm" />
          <Text size="sm">
            Nous divulguerons toute information que nous collectons, utilisons
            ou recevons si la loi l&apos;exige ou l&apos;autorise, par exemple
            pour nous conformer à une citation à comparaître ou à une procédure
            judiciaire similaire, et lorsque nous pensons de bonne foi que la
            divulgation est nécessaire pour protéger nos droits, votre sécurité
            ou celle d&apos;autrui, enquêter sur une fraude ou répondre à une
            demande du gouvernement.
          </Text>
          <Space h="xl" />
          <Text className="font-bold">Informations de contact :</Text>
          <Space h="sm" />
          <Text size="sm">
            Si vous souhaitez nous contacter pour comprendre davantage la
            présente politique ou si vous souhaitez nous contacter concernant
            toute question relative aux droits individuels et à vos informations
            personnelles, vous pouvez envoyer un courriel à contact@bobine.art.
          </Text>
        </Container>
        <Space h="xl" />
      </Container>
      <Space h="xl" />
      <Footer color="#495057" relative />
    </AppShell>
  )
}

export default Privacy
