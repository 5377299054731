import { AspectRatio, Container, Image, ScrollArea, Stack } from '@mantine/core'
import { memo } from 'react'

import { useCurrentMovie } from 'providers/currentMovieProvider'
import { useIsMobile } from 'providers/isMobileProvider'
import MovieGrid from './MovieGrid'

function SynopsisTab() {
  const { isMobile } = useIsMobile()
  const {
    movie: { synopsis, posterHDPath },
  } = useCurrentMovie()

  return (
    <Container fluid px={0} className="h-full overflow-hidden">
      <ScrollArea type="auto" offsetScrollbars={!isMobile} className="h-full">
        <Stack gap="sm" className="h-full pb-3">
          <MovieGrid className="px-2" />

          <div className="mx-2">SYNOPSIS</div>
          <div className="text-sm mx-4">{synopsis}</div>

          {isMobile && (
            <AspectRatio
              className="overflow-hidden w-[55%] mx-auto my-4"
              ratio={3 / 4}
            >
              <Image src={posterHDPath} height="100%" />
            </AspectRatio>
          )}
        </Stack>
      </ScrollArea>
    </Container>
  )
}

export default memo(SynopsisTab)
