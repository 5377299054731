import { Badge, Container, Flex, Text } from '@mantine/core'
import dayjs from 'dayjs'
import React from 'react'
import { Link } from 'react-router-dom'
import { Ticket } from 'tabler-icons-react'
import type { Movie, Theater } from 'types/showtimes'
import { showtimeIsAVP } from 'utils/showtimes'

import MovieDetails from './MovieDetails'
import MovieImage from './MovieImage'
import ShowtimeText from './ShowtimeText'

type Props = {
  movie: Movie
  theaters: Theater[]
}

function MoviePoster({ movie, theaters }: Props) {
  const nbShowtimes = theaters.reduce(
    (acc, theater) => acc + theater.showtimes.length,
    0
  )
  let earliestShowtimeDate: dayjs.Dayjs = dayjs().add(1, 'month')
  let earliestTheaterName = 'No theater'
  let hasEvent = false
  for (const theater of theaters) {
    for (const { showtime, eventInfo } of theater.showtimes) {
      if (earliestShowtimeDate === null || earliestShowtimeDate > showtime) {
        earliestShowtimeDate = showtime
        earliestTheaterName = theater.name
      }
      if (eventInfo) {
        hasEvent = true
      }
    }
  }

  return (
    <Link to={`/film/${movie.urlTitle}-${movie.id}`} state={{ hasPrev: true }}>
      <div className="group mt-1 text-sm select-none">
        <Container
          className="bg-white z-10 relative transition hover:ease-in shadow-sm group-hover:shadow-sm rounded-md"
          px={0}
        >
          <div className="p-[2px]">
            <MovieImage
              movie={movie}
              hasEvent={hasEvent}
              displayAVP={showtimeIsAVP(
                earliestShowtimeDate,
                movie.releaseDate
              )}
            />
          </div>
          <div className="overflow-hidden pt-1 pb-2 z-10 px-2 flex-auto border-zinc-200 rounded-b-md">
            <MovieDetails movie={movie} />
          </div>
        </Container>
        <div className="transition hover:ease-in z-0 shadow-md group-hover:shadow-lg relative px-2 py-1 mx-1 bg-zinc-100 rounded-b-md border border-t-0 border-zinc-200">
          <ShowtimeText
            showtimeDate={earliestShowtimeDate}
            theaterName={earliestTheaterName}
          />
          {nbShowtimes > 1 && (
            <Badge
              color="zinc.1"
              className="shadow-md !border !border-zinc-300 absolute bottom-[-8px] right-[-6px]"
            >
              <Flex gap={3} align="center">
                <Ticket size={13} className="mx-auto text-zinc-700" />
                <Text className="!text-xs !text-zinc-700 !font-bold">
                  {nbShowtimes}
                </Text>
              </Flex>
            </Badge>
          )}
        </div>
      </div>
    </Link>
  )
}

export default React.memo(MoviePoster)
