import { RangeSlider, Stack, Text, Title } from '@mantine/core'

import { useEffect, useState } from 'react'
import useSearchStore from 'states/useSearchStore'
import { formatDuration } from 'utils/utils'

const MIN_DURATION = 0
const MAX_DURATION = 4 * 60
const MARKS = [
  { value: 0, label: '0' },
  { value: 60, label: formatDuration(60) },
  { value: 120, label: formatDuration(120) },
  { value: 180, label: formatDuration(180) },
  { value: MAX_DURATION, label: `${formatDuration(MAX_DURATION)}+` },
]

function getBoldTextSpan(text: string) {
  return <span className="font-bold">{text}</span>
}

function getDurationFilterText(
  minDurationValue: number,
  maxDurationValue: number
) {
  const formattedMinDuration = getBoldTextSpan(formatDuration(minDurationValue))
  const formattedMaxDuration = getBoldTextSpan(formatDuration(maxDurationValue))

  if (minDurationValue === MIN_DURATION && maxDurationValue === MAX_DURATION) {
    return <>Films de toute durée</>
  }

  if (minDurationValue === MIN_DURATION) {
    return <>Films de moins de {formattedMaxDuration}</>
  }

  if (maxDurationValue === MAX_DURATION) {
    return <>Films de plus de {formattedMinDuration}</>
  }

  return (
    <>
      Films de {formattedMinDuration} à {formattedMaxDuration}
    </>
  )
}

function getValueWithDefault(value: number, default_value: number) {
  return value === default_value ? undefined : value
}

export default function DurationSelect() {
  const minDuration = useSearchStore(
    (state) => state.searchParams.minDuration ?? MIN_DURATION
  )
  const maxDuration = useSearchStore(
    (state) => state.searchParams.maxDuration ?? MAX_DURATION
  )
  const updateDurationParams = useSearchStore(
    (state) => state.updateDurationParams
  )
  const [values, setValues] = useState<[number, number]>(() => [
    minDuration,
    maxDuration,
  ])

  const [minDurationValue, maxDurationValue] = values

  useEffect(() => {
    setValues([minDuration, maxDuration])
  }, [minDuration, maxDuration])

  return (
    <Stack>
      <Title order={5}>Durée</Title>
      <Text size="sm">
        {getDurationFilterText(minDurationValue, maxDurationValue)}
      </Text>
      <RangeSlider
        size="lg"
        color="zinc.6"
        minRange={10}
        step={10}
        min={MIN_DURATION}
        max={MAX_DURATION}
        value={values}
        onChange={setValues}
        onChangeEnd={([newMinDuration, newMaxDuration]) =>
          updateDurationParams({
            minDuration: getValueWithDefault(newMinDuration, MIN_DURATION),
            maxDuration: getValueWithDefault(newMaxDuration, MAX_DURATION),
          })
        }
        marks={MARKS}
        label={null}
      />
    </Stack>
  )
}
