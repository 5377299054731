import { Badge, Indicator, Text, UnstyledButton } from '@mantine/core'
import { forwardRef, memo, useMemo } from 'react'

import InfoBubble from 'components/common/InfoBubble'
import { formatAudio, formatSubtitles } from 'utils/showtimes'

function badgeStyle(
  isSelected: boolean | undefined,
  isUnavailable: boolean | undefined
) {
  if (isUnavailable) {
    return 'bg-zinc-100 text-zinc-300 cursor-not-allowed'
  }
  if (isSelected) {
    return 'bg-zinc-600 hover:bg-zinc-700 text-zinc-100 cursor-pointer'
  }
  return 'bg-zinc-200 hover:bg-zinc-300 text-zinc-600 cursor-pointer'
}

type Props = {
  showtimeText: string
  vo: boolean
  audio: string | null
  subtitles: string[]
  is3d: boolean
  extraInfo: string | null
  price: string | null
  showPrice: boolean
  isAvantPremiere: boolean
  isSelected?: boolean // eslint-disable-line react/require-default-props
  isUnavailable?: boolean // eslint-disable-line react/require-default-props
  isEvent: boolean
}
const ShowtimeBadgeTarget = forwardRef<HTMLButtonElement, Props>(
  (
    {
      showtimeText,
      vo,
      audio,
      subtitles,
      is3d,
      extraInfo,
      price,
      showPrice,
      isAvantPremiere,
      isSelected = false,
      isUnavailable = false,
      isEvent,
      ...others
    }: Props,
    ref
  ) => {
    const style = useMemo(
      () => badgeStyle(isSelected, isUnavailable),
      [isSelected, isUnavailable]
    )
    const eventColor = isEvent ? 'bg-event' : 'bg-avp'
    const audioText = formatAudio(vo, audio, false)

    let badge = (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <UnstyledButton ref={ref} {...others}>
        <Badge
          unstyled
          key={showtimeText}
          className={`font-bold rounded-lg text-center ${style} py-2
          } leading-snug w-[84px]`}
        >
          {showtimeText}
          <Text fw={800} size="xs">
            {audioText}
            <span className="italic font-normal">
              {formatSubtitles(subtitles, false)}
              {is3d && ' - 3D'}
            </span>
          </Text>
          {showPrice && <Text size="xs">{price}</Text>}
        </Badge>
      </UnstyledButton>
    )

    if (isAvantPremiere) {
      badge = (
        // color corresponds to tailwind class bg-yellow-500
        <Indicator
          inline
          color="transparent"
          classNames={{ indicator: eventColor }}
          size={16}
          offset={2}
          zIndex={10}
        >
          {badge}
        </Indicator>
      )
    }

    if (!extraInfo) {
      return badge
    }

    return (
      <Indicator
        inline
        position="bottom-end"
        color="transparent"
        label={
          <InfoBubble
            bubbleText={extraInfo}
            className={isSelected ? 'text-zinc-600' : 'text-zinc-300'}
            dropdownClassName={
              isSelected
                ? 'font-bold text-zinc-100 bg-zinc-600'
                : 'font-bold text-zinc-600 bg-zinc-200 border-white'
            }
          />
        }
        size={16}
        offset={4}
        radius="lg"
        zIndex={10}
        classNames={{ indicator: 'hover:z-20' }}
      >
        {badge}
      </Indicator>
    )
  }
)

export default memo(ShowtimeBadgeTarget)
