import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import type { DateTimeParams } from 'types/showtimes'

dayjs.extend(timezone)

export const DEFAULT_ADDRESS = 'Ma position'
export const FAVORITE_ADDRESS = 'Mes cinémas favoris'

export const RANGES = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 40, 50, 70, 100,
]

export const APPLE_STORE_URL =
  'https://apps.apple.com/fr/app/bobine/id6443939999'
export const ANDROID_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.bobine.bobineart&utm_source=web'

export function globalVar<T>(name: keyof Window): T | undefined {
  if (
    name in window &&
    window[name] !== undefined &&
    window[name] !== 'undefined'
  ) {
    return window[name] as T
  }
  return undefined
}

export function globalFunc(
  name: keyof Window,
  callback?: (data: any) => void
): void {
  const func = globalVar<(callback?: (data: any) => void) => void>(name)
  if (func) {
    func(callback)
  }
}

export function formatDuration(duration: number) {
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60
  if (hours === 0) {
    return `${minutes} min`
  }
  if (minutes === 0) {
    return `${hours} h`
  }
  return `${hours} h ${minutes.toString().padStart(2, '0')} min`
}

export function formatReleaseDate(releaseDate: dayjs.Dayjs | null) {
  if (releaseDate === null) {
    return null
  }
  const format =
    releaseDate.year() >= dayjs().year() - 1 ? 'D MMM YYYY' : 'YYYY'
  return dayjs(releaseDate).locale('fr').format(format)
}

export function computeStartEndForSingleDay(
  day: string,
  startHour: number | undefined,
  endHour: number | undefined
) {
  let start: dayjs.Dayjs
  let end: dayjs.Dayjs
  const date = dayjs().add(Number.parseInt(day, 10), 'days')
  const minDate = dayjs().subtract(10, 'minutes')
  const currentHour = minDate.hour()
  const startOfDay = date.startOf('day')
  const endOfDay = date.endOf('day')

  if (day === '-1') {
    // Set dates to the next hour
    start = minDate
    end = dayjs().add(1, 'hour')
  } else if (day === '0') {
    start =
      startHour === undefined || startHour <= currentHour
        ? minDate
        : startOfDay.hour(startHour)
    if (endHour === undefined) {
      end = endOfDay
    } else {
      end =
        endHour <= currentHour
          ? start.subtract(1, 'minutes') // Triggers the empty result for the time in the past
          : startOfDay.hour(endHour)
    }
  } else {
    start = startHour === undefined ? startOfDay : startOfDay.hour(startHour)
    end = endHour === undefined ? endOfDay : startOfDay.hour(endHour)
  }
  return { start, end }
}

export function computeStartEndForSeveralDays(
  days: string[],
  startHour: number | undefined,
  endHour: number | undefined
) {
  const starts: dayjs.Dayjs[] = []
  const ends: dayjs.Dayjs[] = []
  // If the end hour is after the current hour, we add the current day
  for (const d of days) {
    if (
      d === '0' &&
      endHour !== undefined &&
      endHour <= dayjs().subtract(10, 'minutes').hour()
    ) {
      continue
    }
    const { start, end } = computeStartEndForSingleDay(d, startHour, endHour)
    starts.push(start)
    ends.push(end)
  }
  return { start: starts, end: ends }
}

export function computeStartEnd({ day, startHour, endHour }: DateTimeParams) {
  if (day === undefined && (startHour !== undefined || endHour !== undefined)) {
    return computeStartEndForSeveralDays(
      ['0', '1', '2', '3', '4', '5', '6'],
      startHour,
      endHour
    )
  }
  if (day !== undefined) {
    return computeStartEndForSingleDay(day, startHour, endHour)
  }
  return { start: undefined, end: undefined }
}
