import { useQuery } from 'react-query'
import { type Theater, apiToTheater } from 'types/showtimes'
import apiFetch from './common'

async function theaterById(theaterId: number | undefined): Promise<Theater> {
  // Fetching the theater from the API
  const data = await apiFetch(`/api/theaters/${theaterId}`)()
  return apiToTheater(data)
}

export default function useTheaterById(theaterId: number | undefined) {
  return useQuery<Theater, Error>(
    ['theater', theaterId],
    () => theaterById(theaterId),
    { enabled: !!theaterId }
  )
}

async function theaterSearch(
  searchText: string,
  page: number,
  pageSize: number
) {
  const params = { searchText, page, pageSize }
  const theaters: any = await apiFetch('/api/theaters/search', params)()
  return theaters.map(apiToTheater)
}

export function useTheatersSearch(
  searchText: string,
  page: number,
  pageSize: number
) {
  return useQuery<Theater[], Error>(
    ['theaters_search', searchText, page, pageSize],
    () => theaterSearch(searchText, page, pageSize),
    { enabled: !!searchText }
  )
}
