import { Group, Stack } from '@mantine/core'
import { memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

import Favorite from 'components/theater/Favorite'
import TheaterAnchor from 'components/theater/TheaterAnchor'
import { useCurrentMovie } from 'providers/currentMovieProvider'
import useDisplayStore from 'states/useDisplayStore'
import useSearchStore from 'states/useSearchStore'
import type { Showtime } from 'types/showtimes'
import PriceToggle from './PriceToggle'
import ShowtimeBadge from './ShowtimeBadge'

function ShowtimesList() {
  const { movie, matchingTheaters, allTheaters } = useCurrentMovie()
  const showPrices = useSearchStore((state) => state.showPrices)
  const date = useDisplayStore((state) => state.currentDate)
  const keyCount: { [key: string]: number } = {}

  const dayTheaters = useMemo(() => {
    if (!date) {
      return []
    }
    return allTheaters
      .map((theater) => {
        const dayShowtimes = theater.showtimes.filter(({ showtime }) =>
          showtime.isSame(date, 'day')
        )
        return { theater, dayShowtimes }
      })
      .filter(({ dayShowtimes }) => dayShowtimes.length > 0)
  }, [allTheaters, date])

  if (!date) {
    return null
  }

  return (
    <Stack>
      <PriceToggle />
      {dayTheaters.map(({ theater, dayShowtimes }) => {
        const daySelectedShowtimes: Showtime[] =
          matchingTheaters
            .find(
              (t) => t.name === theater.name && t.address === theater.address
            )
            ?.showtimes.filter(({ showtime }) =>
              showtime.isSame(date, 'day')
            ) || []

        return (
          <div key={theater.name}>
            <Group
              justify="space-between"
              align="center"
              style={{ gap: 'unset' }}
            >
              <Group gap={8} className="group">
                <Link
                  to={`/cinema/${theater.urlTitle}-${theater.id}`}
                  state={{ hasPrev: true }}
                >
                  {theater.name}
                  <div className="border-b-2 border-zinc-400 w-1/4 transition-all duration-300 group-hover:w-1/3">
                    {null}
                  </div>
                </Link>
                <div>
                  <Favorite theater={theater} size={20} />
                </div>
              </Group>
              <TheaterAnchor theater={theater} />
            </Group>
            <Group gap="md" className="pt-1">
              {dayShowtimes.map((showtime) => {
                const time = showtime.showtime.format('HH:mm')
                keyCount[time] = (keyCount[time] || 0) + 1
                return (
                  <ShowtimeBadge
                    showtime={showtime}
                    movie={movie}
                    theater={theater}
                    isSelected={daySelectedShowtimes.some((s) =>
                      s.showtime.isSame(showtime.showtime)
                    )}
                    showPrice={showPrices}
                    key={`${time}-${keyCount[time]}`}
                  />
                )
              })}
            </Group>
          </div>
        )
      })}
    </Stack>
  )
}

export default memo(ShowtimesList)
