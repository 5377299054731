import {
  ActionIcon,
  Container,
  Popover,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core'
import type React from 'react'
import type { ReactNode } from 'react'
import { X } from 'tabler-icons-react'

type Props = {
  opened: boolean
  setOpened: (arg: boolean) => void
  children: ReactNode
  icon: ReactNode
  color: string
  width: string
  tooltipText?: string | null
  text?: string | React.ReactElement | null
}

export default function MobileButton({
  opened,
  setOpened,
  children,
  icon,
  width,
  color,
  tooltipText = null,
  text = null,
}: Props) {
  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      width={width}
      position="bottom"
      trapFocus={opened}
      keepMounted
      closeOnClickOutside
    >
      <Popover.Target>
        <Tooltip
          multiline
          label={tooltipText}
          disabled={tooltipText == null}
          position="bottom"
          w={110}
          events={{ hover: true, focus: false, touch: false }}
          closeDelay={100}
        >
          <Stack
            gap={2}
            onClick={() => {
              setOpened(!opened)
            }}
            align="center"
            justify="center"
            className={`relative overflow-hidden cursor-pointer h-full w-full z-10  ${color}`}
          >
            {icon}
            {text && (
              <Text lineClamp={1} size="sm" fw={500} className="select-none">
                {text}
              </Text>
            )}
          </Stack>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown
        className="px-0 py-0 !bg-transparent !border-none mah-screen"
        onClick={(e: any) => {
          setOpened(false)
          e.stopPropagation()
        }}
      >
        <div className="mx-2 px-2 flex bg-white py-2 rounded-md shadow-md border-2 border-zinc-300">
          <Container
            className="w-full"
            onClick={(e: any) => {
              e.stopPropagation()
            }}
          >
            {children}
          </Container>
          <ActionIcon
            onClick={() => setOpened(false)}
            variant="transparent"
            className="cursor-pointer ml-2 mr-0 pt-3 mb-auto text-zinc-500"
          >
            <X size={20} />
          </ActionIcon>
        </div>
      </Popover.Dropdown>
    </Popover>
  )
}
