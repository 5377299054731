import { Modal, Stack } from '@mantine/core'
import { memo } from 'react'

import type ShowtimeContext from 'types/ShowtimeContext'
import ShowtimeCard from './ShowtimeCard'
import ShowtimeEventBanner from './ShowtimeEventBanner'
import ShowtimeLanguageBox from './ShowtimeLanguageBox'
import ShowtimeLinks from './ShowtimeLinks'
import ShowtimeModalTitle from './ShowtimeModalTitle'

function ShowtimeModal({
  showtimeContext,
  opened,
  onClose,
}: {
  showtimeContext: ShowtimeContext
  opened: boolean
  onClose: () => void
}) {
  const { showtime, movie, isAvantPremiere, shareTitle, shareUrl } =
    showtimeContext
  const { vo, audio, subtitles, eventInfo, id } = showtime
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      onKeyDown={(e) => {
        e.stopPropagation()
      }}
      centered
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      classNames={{
        root: 'rounded-lg',
        body: 'bg-zinc-100',
        header: 'bg-zinc-100',
      }}
      title={<ShowtimeModalTitle movie={movie} />}
    >
      <Stack justify="center">
        <ShowtimeEventBanner
          eventInfo={eventInfo}
          isAvantPremiere={isAvantPremiere}
        />
        <ShowtimeCard showtimeContext={showtimeContext} />
        <ShowtimeLanguageBox vo={vo} audio={audio} subtitles={subtitles} />
        <ShowtimeLinks
          showtimeId={id}
          shareUrl={shareUrl}
          shareTitle={shareTitle}
          movieUrl={`/film/${movie.urlTitle}-${movie.id}`}
          onClose={onClose}
        />
      </Stack>
    </Modal>
  )
}

export default memo(ShowtimeModal)
