import { ANDROID_STORE_URL } from 'utils/utils'
import GenericBadge from './GenericBadge'

type Props = {
  scale?: number
}

export default function AndroidBadge({ scale }: Props) {
  const aspectRatio = 200 / 68

  return (
    <GenericBadge
      url={ANDROID_STORE_URL}
      imgSrc="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png"
      imgAlt="Disponible sur Google Play"
      scale={scale}
      aspectRatio={aspectRatio}
      badgeType="android"
    />
  )
}
AndroidBadge.defaultProps = {
  scale: 1,
}
