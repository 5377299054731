import { useCallback, useEffect, useState } from 'react'

export const isBrowser = typeof window !== 'undefined' && window.document

const loadScript = (url: string, callback: any) => {
  if (!isBrowser) {
    return null
  }

  if (typeof google !== 'undefined') {
    if (google.maps) {
      return null
    }
  }
  // adding an empty callback
  const scriptEmpty = document.createElement('script')
  scriptEmpty.innerHTML = 'var emptyCallback = function () {return;}'
  document.getElementsByTagName('head')[0].appendChild(scriptEmpty)

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = url
  script.onload = () => callback()
  script.onerror = () => {
    console.error('Error loading ', script.src)
  }

  document.getElementsByTagName('head')[0].appendChild(script)
  return null
}

export default function useAutocompleteServices(apiKey: string, options: any) {
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService>()
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService>()
  const [isPlacePredsLoading, setIsPlacePredsLoading] = useState<boolean>()
  const [placePredictions, setPlacePredictions] =
    useState<google.maps.places.AutocompletePrediction[]>()
  const [autocompleteSessionToken, setAutocompleteSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>()
  const [refreshSessionToken, setRefreshSessionToken] = useState<() => void>(
    // eslint-disable-line react/function-component-definition
    () => () => null // eslint-disable-line react/function-component-definition
  ) // eslint-disable-line react/function-component-definition
  const getPlacePredictions = useCallback(
    (input: string) => {
      if (autocompleteService) {
        autocompleteService.getPlacePredictions(
          {
            input,
            ...options,
            ...(autocompleteSessionToken
              ? { sessionToken: autocompleteSessionToken }
              : {}),
          },
          (response: any) => {
            setIsPlacePredsLoading(false)
            setPlacePredictions(response || [])
          }
        )
      }
    },
    [autocompleteService, autocompleteSessionToken, options]
  )

  useEffect(() => {
    if (!isBrowser) {
      return
    }
    const buildService = () => {
      if (!google) {
        return console.error(
          'Google has not been found. Make sure your provide apiKey prop.'
        )
      }
      setAutocompleteService(new google.maps.places.AutocompleteService())

      setPlacesService(
        new google.maps.places.PlacesService(document.createElement('div'))
      )

      setAutocompleteSessionToken(
        new google.maps.places.AutocompleteSessionToken()
      )
      setRefreshSessionToken(
        () => () =>
          setAutocompleteSessionToken(
            new google.maps.places.AutocompleteSessionToken()
          )
      )
      return null
    }

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=emptyCallback`,
      () => buildService()
    )
  }, [apiKey])

  return {
    autocompleteSessionToken,
    placesService,
    placePredictions,
    isPlacePredsLoading,
    getPlacePredictions,
    refreshSessionToken,
  }
}
